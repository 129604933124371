import React, { useRef, useEffect } from 'react'
import InfoSection from "../../compoents/InfoSection/InfoSection"
import Discounts from "../../compoents/Discounts/Discounts"
import Services from "../../compoents/Services/Services"
import Navbar from "../../compoents/Navbar/Navbar";
import Footer from "../../compoents/Footer/Footer";
import { homeObjOne1, homeObjOne2, homeObjOne3, homeObjOne4 } from "../Data";


const Home = () => {

  const discounts = useRef(null);
  const services = useRef(null);

  useEffect(() => {
    // Check if the refs are correctly assigned after the component mounts
    console.log("Refs initialized:", {
      services: services.current,
      discounts: discounts.current,
    });
  }, []);


  const scrollToSection = (elementRef) => {
    console.log(elementRef)
    if (elementRef?.current) {
      window.scrollTo({
        top: elementRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Navbar scrollToSection={scrollToSection} 
        discounts={discounts} 
        services={services} 
      />
      <InfoSection {...homeObjOne1} />
      <Discounts ref={discounts} {...homeObjOne2}/>
      <Services ref={services} {...homeObjOne3}/>
      <InfoSection {...homeObjOne4} />
      <Footer />
    </>
  );
}

export default Home